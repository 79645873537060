import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	standalone: true,
	imports: [MatIconModule, NgIf, NgTemplateOutlet],
})
export class ButtonComponent {
	@Input() svgIcon?: string;
	@Input() text?: string;
	@Input() loading?: boolean;
	@Input() disabled?: boolean;

	// @Output() click = new EventEmitter<void>();
	// @Output() longClick = new EventEmitter<void>();
	// @Output() doubleClick = new EventEmitter<void>();
	// @Output() mouseClick = new EventEmitter<void>();

	// ngAfterContentChecked(): void {
	// 	console.log('>>>>> ', this.content.elementRef.nativeElement);
	// }
}
