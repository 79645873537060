import {Injectable} from '@angular/core';

import {environment} from '../../../environments/environment';
import {IFrontDescription} from '@shared/repositories/api.repository';

export interface IConfigEnterprise {
	backgroundUrl: string;
	name: string;
	description: string;
	shortName: string;
	url: string;
	logoUrl: string;
	isMainEnterprise: boolean;
}

export interface IConfig {
	wssUrl: string;
	javaApiUrl: string;
	apiUrl?: string;
	qrApi?: string;
	resetPassTime?: string;
	resetPassURL?: string;
	isWssCheckerEnable?: string;

	videoApi: string;
	ascueRootLocationId: number;
	rootLocationId: number;
	minDateForInterval: Date;
	ftpServer: string;
	nonProductionUnit: Array<number>;
	locationSlider: Array<number>;
	liftLocationId: number;
	videocamServer: string;
	videocam: boolean;
	enterprises: IConfigEnterprise[];
	userGuideUrl: string;
	whatsNew: { text: string, date: Date };

	scannerIgnored: string[];
	scannerIgnoredPrefix?: string;
}

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	private readonly config: IConfig;

	constructor() {
		this.config = {
			wssUrl: environment.wssUrl,
			javaApiUrl: environment.javaApiUrl,

			videoApi: environment.javaApiUrl,
			ftpServer: environment.javaApiUrl,
			videocamServer: environment.javaApiUrl,
			userGuideUrl: environment.javaApiUrl,
			whatsNew: {text: '', date: null},
			videocam: false,

			ascueRootLocationId: 0,
			rootLocationId: 0,
			liftLocationId: 0,
			minDateForInterval: new Date('2024-01-01'),
			nonProductionUnit: [],
			locationSlider: [],
			enterprises: [
				{
					shortName: 'OWL',
					name: 'OWL SCADA',
					url: '/',
					logoUrl: 'assets/img/kk_logo2.png',
					description: `OWL SCADA`,
					backgroundUrl: 'assets/img/kkbg.png',
					isMainEnterprise: true,
				}
			],
			scannerIgnored: [],
		};
	}

	public getConfig<TKey extends keyof IConfig>(
		keys: TKey[]
	): Pick<IConfig, TKey> {
		return keys.reduce((obj, key) => {
			if (this.config.hasOwnProperty(key)) {
				obj[key] = this.config[key];
			}

			return obj;
		}, {} as Pick<IConfig, TKey>);
	}

	public getApiUrl(): string {
		return this.getConfig([
			'javaApiUrl',
		]).javaApiUrl;
	}

	// Простовляем полученные настройки
	public setConfig(settings: IFrontDescription): void {
		if (settings) {
			Object.keys(settings).forEach(settingKey => {
				if (!settings[settingKey]) {
					this.config[settingKey] = null;
				} else if (settingKey === 'scannerIgnored') {
					this.config[settingKey] = settings[settingKey].split(',').map(s => s.trim());
				} else if (typeof this.config[settingKey] === 'boolean') {
					this.config[settingKey] = Boolean(settings[settingKey]);
				} else if (typeof this.config[settingKey] === 'number') {
					this.config[settingKey] = Number(settings[settingKey]);
				} else {
					if (this.config[settingKey] instanceof Date) {
						this.config[settingKey] = new Date(settings[settingKey]);
					} else if (Array.isArray(this.config[settingKey]) && settingKey !== 'enterprises') {
						const re = /\s*,\s*/;
						const arrayStr = settings[settingKey].split(re);

						const array = arrayStr.map(strItem => {
							const item = strItem.replace(' ', '');

							if ((typeof item) === 'number') {
								return Number(item);
							} else if ((typeof item) === 'boolean') {
								return Boolean(item);
							} else {
								return item;
							}
						});
						this.config[settingKey] = array;
					} else {
						this.config[settingKey] = settings[settingKey];
					}
				}
			});
		}
	}

	public getRootEnterprise(): IConfigEnterprise {
		return this.config.enterprises.filter(item => item.isMainEnterprise)[0];
	}
}
