<button
	class="app-button"
	[disabled]="disabled"
>
	@if (loading) {
		<div class="loader">
			<span></span>
		</div>
	} @else {
		<mat-icon
			*ngIf="svgIcon"
			class="app-button__icon"
			[svgIcon]="svgIcon"
		></mat-icon>

		<span class="app-button__text g-unselectable">
			<span
				*ngIf="text"
			>{{ text }}</span>

			<ng-content class="app-button__text"></ng-content>
		</span>
	}
</button>
