export const apiState = {
	TaskByRoleUser: 'task/user-role-in-task',
	externalGateway: 'external',
	SDGateway: 'sdweb',
	AuthApi: 'auth',
	SearchApi: 'search',
	Task: 'task',
	ServiceApi: 'service',
	WorkflowApi: 'workflow',
	StatusTransitionApi: 'statustransition',
	TaskStatusApi: 'status',
	TaskPriorityApi: 'priority',
	TaskFieldApi: 'taskfield',
	FieldTypeApi: 'taskfield/typedata',
	UserRoleApi: 'user/userrole',
	UserTypeApi: 'user/usertype',
	EmployeeApi: 'user/employee',
	EmployeeGroupApi: 'group/employeegroup',
	ClientApi: 'user/client',
	ClientCompanyApi: 'group/clientcompany',
	FileApi: 'files',
	Bind: 'bind',
	Join: 'join=',
	Tree: 'structure=tree',
	Range: 'range=',
	Sort: 'sort=',
	Volume: 'volume=',
	Short: 'short',
	Active: 'active=',
	UserType: 'usertype=',
	Client: 'client',
	Employee: 'employee',
	EmployeeGroup: 'employeegroup',
	TaskListRoute: 'task/list',
	SchedulerRoute: 'task/schedule',
	UnitList: 'unit',
	Comment: 'comment'
};
