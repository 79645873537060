import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NotifierService} from 'angular-notifier';

import {ConfigService, IConfig} from '@shared/services/config.service';
import {IPartialInterval} from '@shared/ui-components/n-inverval/models/interval.types';
import {ISDTask, ISDTaskFilter, ISDTaskIncomplete, ITaskParameter, SimpleNomRequest} from '../../modules/sd/models/sd-task.interface';
import {IWorkflowData, SDService, TaskStatusTransitionDTO} from '../../modules/sd/models/sd-service.interface';
import {SDUser} from '../../modules/sd/models/sd-user.interface';
import {SdApiRepository} from './sd-api.repository';
import {apiState} from '@shared/components/sd/models/apiState';
import {IReleaseNotes} from '@shared/models/release-notes';
import {IPermissions, IUnitClass, IUnitGroup, IUser} from '@store/types';
import {ILocationTree} from '@shared/models/location-tree';
import {IInterval, IPostInterval} from '@shared/models/interval';
import {IGraph} from './api-counter.repository';
import {IRepairTask} from '../../modules/sd/modules/equipment/modules/mes-active-unit/modules/task-repair-item/models/task-repair-item.types';
import {ILabelMovement, IMovement, IPCTerminal} from '../../modules/sd/modules/materials-movement/models/materials-movement.types';
import {IUnitFull} from '../../modules/monitoring/modules/unit/models/unit';
import {IMesParameter} from '@shared/components/mes-parametr/models/mes-parameter.types';

export interface ISettings<T extends string> {
	key: T; // определение типа ключа
	value: any;
}

export interface ICreateUser {
	login: string;
	name: string;
	password: string;
	patronymic: string;
	surname: string;
	tabNumber: string;
	isNew: boolean;
	email?: string;
	messengerName?: string;
}

export interface IEditUser extends Partial<ICreateUser> {
}

export interface IStatus {
	status: 'error' | 'ok';
}

export interface IRole {
	id: number;
	name: string;
	description: string;
	basic: boolean;
}

export interface IUserRoles {
	locationsIds: number[];
	lockLocation: number[];
	lockRoles: number[];
	rolesIds: number[];
	userId: number;
}

export interface IEditUserRoles extends Partial<IUserRoles> {
}

export interface IStatusChart {
	title: string;
	orderNumber: number;
	color: string;
	id: number;
}

export interface IItemChart {
	absolute: number;
	percent: number;
	value: number;
	status: IStatusChart;
}

export interface IStaticChart {
	intervalDate: IInterval;
	value: {
		[key: string]: IItemChart[];
	};
}

export interface IIdleChart {
	[key: string]: {
		[key2: string]: {
			id: number;
			color: string;
			interval: IInterval;
			percent: number;
			title: string;
			unitId: number;
		};
	};
}

export interface IIdleStat {
	absoluteDuration: number;
	color: string;
	percent: number;
	title: string;
}

export interface IFilter {
	id: number;
	color?: string;
	isChecked?: boolean;
	name: string;
	title: string;
	type: string;
}

export interface IPostTotalStat {
	startDate: Date;
	endDate: Date;
	filterUnitIds: number[];
	selectedStatisticOptions: number[];
}

export interface IPostTotalStatUnits {
	startDate: Date;
	endDate: Date;
	locationIds: bigint[];
	selectedStatisticOptions: number[];
	filterUnitIds: bigint[];
}

export interface IPostDynamicStatUnits {
	startDate: Date;
	endDate: Date;
	period: number;
	selectedStatisticOptions: number[];
	unitIds: number[];
	userIds: number[];
}

export interface IPostDynamicStatLocation {
	startDate: Date;
	endDate: Date;
	locationIds: number[];
	userIds: number[];
	period: number;
	selectedStatisticOptions: number[];
	filterUnitIds: number[];
}

export interface IStatTotal {
	diagramType: string;
	intervalDate: IInterval;
	lineItems: string[];
	splineItems: string[];
	staticStatisticItems: IItemChart[];
}

export interface IPostStat {
	intervalDate:
		| IInterval
		| {
		start: string;
		end: string;
	};
	value: number[];
}

export interface IReport {
	path?: string;
	error?: string;
}

export interface ITimeline {
	[key: string]: {
		interval: IInterval;
		absolute: number;
		status: IStatusChart;
	}[];
}

export interface IUnitRepairChar {
	[key: string]: {
		absolute: number;
		address: string;
		breakType: string;
		color: string;
		end: Date;
		key: string;
		start: Date;
		status: IStatusChart;
		taskId: number;
	}[];
}

export interface IDayAndRollingRepairTask {
	dayIssues: IRepairTask[];
	rollingIssues: IRepairTask[];
}

export interface IAlarmLevel {
	id: number;
	level: number;
	name: string;
}

export interface IFTP {
	name: string;
	type: number;
}

export interface IPostTechprocess {
	startDate: string | Date;
	endDate: string | Date;
	unitIds: number[];
}

export interface IStatTechproces {
	totalTechProcessStatistics: {
		unitId: number;
		totalDuration: number;
		count: number;
		color: string;
		name: string;
	}[];
	unitsTechProcessStatistics: {
		[key: string]: {
			unitId: number;
			totalDuration: number;
			count: number;
			color: string;
			name: string;
		}[];
	};
}

export interface IPostCoefficient {
	coefficients: number[];
	ids: number[];
	intervalDate: IPartialInterval;
}

export interface IStatusCoefficient {
	color: string;
	description: string;
	id: number;
	measure: string;
	title: string;
	properties: {
		color: string;
		description: string;
		id: number;
		measure: string;
		name: string;
		title: string;
	}[];
}

export interface IItemCoefficient {
	absolute: number;
	percent: number;
	value: number;
	status: IStatusCoefficient;
}

export interface ICoefficient {
	[number: string]: {
		AQ: IItemCoefficient;
		LOAD: IItemCoefficient;
		MDT: IItemCoefficient;
		MTBF: IItemCoefficient;
		MTTA: IItemCoefficient;
		MTTF: IItemCoefficient;
		OEE: IItemCoefficient;
		TASKSCOUNT: IItemCoefficient;
	};
}

export interface IFrontDescription {
	ascueRootLocationId: string;
	enterprises: {
		backgroundUrl: string;
		description: string;
		isMainEnterprise: 'true' | 'false';
		logoUrl: string;
		name: string;
		shortName: string;
		url: string;
	}[];
	ftpServer: string;
	jira: 'true' | 'false';
	liftLocationId: string;
	locationSlider: string;
	minDateForInterval: string;
	nonProductionUnit: string;
	qrApi?: string;
	resetPassTime: string;
	resetPassURL: string;
	rootLocationId: string;
	userGuideUrl: string;
	videoApi: string;
	videocam: string;
	videocamServer: string;
}

export interface IFormula {
	id: number;
	description: string;
	parameterId: number;
	splittedText: string[];
	text: string;
	title: string;
	lastCalculatedTime: Date;
	poolingTime: number;
}

export interface IPostReliabilityReport {
	req: IInterval;
	searchField: string[];
	unitIds: number[];
}

export interface IPathFile {
	error?: string;
	path?: string;
}

export interface IDictionary {
	id: number;
	remoteIdStr: string;
	name: string;
	parameterId: number;
	color: string;
	weight: number;
	archived?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class ApiRepository {
	private _config: Pick<IConfig, 'javaApiUrl' | 'rootLocationId'>;

	constructor(
		private _http: HttpClient,
		private _globalConfig: ConfigService,
		private _notifierService: NotifierService,
		private _sdApiService: SdApiRepository
	) {
		this._config = this._globalConfig.getConfig([
			'javaApiUrl',
			'rootLocationId',
		]);
	}

	public getSettings<T extends string>(
		settingNames: Array<string>
	): Observable<ISettings<T>[]> {
		const url = `${this._config.javaApiUrl}/core/settings/post`;

		return this._http.post(url, settingNames).pipe(
			catchError(err => {
				const errorText = err.message ? err.message : '';
				this._notifierService.notify(
					'error',
					'Ошибка при получении данных. ' + errorText
				);
				return of(err);
			})
		);
	}

	public getUsers(): Observable<IUser[]> {
		const url = `${this._config.javaApiUrl}/auth/user/get/all`;

		return this._http.get<IUser[]>(url);
	}

	public getUserById(userId: number): Observable<IUser> {
		const url = `${this._config.javaApiUrl}/auth/user/get/${userId}`;

		return this._http.get<IUser>(url);
	}

	public saveAddParameter(
		userAddFieldValues: SimpleNomRequest
	): Observable<boolean> {
		const url = `${this._config.javaApiUrl}/auth/user/update-add-parameter`;

		return this._http.post<boolean>(url, userAddFieldValues);
	}

	public sendTemporaryPassword(idUser: number, pass: string): Observable<void> {
		const url = `${this._config.javaApiUrl}/core/info/send-system-message`;

		return this._http.post<void>(url, {
			filter: [idUser],
			value: pass,
		});
	}

	public getUserRoles(userId: number): Observable<IUserRoles> {
		const url = `${this._config.javaApiUrl}/auth/user/${userId}/roles`;

		return this._http.get<IUserRoles>(url);
	}

	public getRoles(): Observable<IRole[]> {
		const url = `${this._config.javaApiUrl}/auth/user/roles/info/`;

		return this._http.get<IRole[]>(url);
	}

	public createUser(data: ICreateUser): Observable<IStatus> {
		const url = `${this._config.javaApiUrl}/auth/user/create`;

		return this._http.post(url, data).pipe(
			catchError(error => {
				console.log(`get error with createUser: ${error}`);
				return of(error);
			})
		);
	}

	public editUser(data: IEditUser): Observable<IStatus> {
		const url = `${this._config.javaApiUrl}/auth/user/update`;

		return this._http.post(url, data).pipe(
			catchError(error => {
				console.log(`get error with editUser: ${error}`);
				return of(error);
			})
		);
	}

	public removeUser(id: number): Observable<IStatus> {
		const url = `${this._config.javaApiUrl}/auth/user/remove/${id}`;

		return this._http.get(url).pipe(
			catchError(error => {
				console.log(`get error with editUser: ${error}`);
				return of(error);
			})
		);
	}

	public editUserRoles(data: IEditUserRoles): Observable<IUserRoles> {
		const url = `${this._config.javaApiUrl}/auth/user/roles/functional/update`;

		return this._http.post(url, data).pipe(
			catchError(error => {
				console.log(`get error with editUserRoles: ${error}`);
				return of(error);
			})
		);
	}

	public editUserLocations(data: IEditUserRoles): Observable<IUserRoles> {
		const url = `${this._config.javaApiUrl}/auth/user/roles/location/update`;

		return this._http.post(url, data).pipe(
			catchError(error => {
				console.log(`get error with editUserLocations: ${error}`);
				return of(error);
			})
		);
	}

	public getAllUnitClasses(): Observable<IUnitClass[]> {
		return this._http.get<IUnitClass[]>(
			this._config.javaApiUrl + '/core/unit/all-unit-classes'
		);
	}

	public getAllUnitGroups(): Observable<IUnitGroup[]> {
		return this._http.get<IUnitGroup[]>(
			this._config.javaApiUrl + '/core/unit/all-unit-groups'
		);
	}

	public getAllUnitsLite(locationId: number): Observable<IUnitFull[]> {
		return this._http.get<IUnitFull[]>(
			`${this._config.javaApiUrl}/core/location/${locationId}/units-lite`
		);
	}

	public getLocationTree(locationId: number): Observable<ILocationTree> {
		return this._http.get<ILocationTree>(
			`${this._config.javaApiUrl}/core/location/${locationId}/tree`
		);
	}

	public getUnitsDynamicCharts(
		ids: number[],
		interval: IPartialInterval
	): Observable<ITimeline> {
		const payload = {
			intervalDate: {
				start: interval.start,
				end: interval.end,
			},
			value: ids,
		};

		return this._http.post<ITimeline>(
			this._config.javaApiUrl + '/core/unit/statistic/timeline',
			payload
		);
	}

	public getUnitsStaticChart(
		ids: number[],
		interval?: IPartialInterval
	): Observable<IStaticChart> {
		return this._http.post<IStaticChart>(
			this._config.javaApiUrl + '/core/unit/statistic/pie',
			{
				intervalDate: {
					start: interval.start,
					end: interval.end,
				},
				value: ids,
			}
		);
	}

	public getUnitsRepairChart(
		ids: number[],
		interval: IPartialInterval
	): Observable<IUnitRepairChar> {
		const payload = {
			intervalDate: {
				start: interval.start,
				end: interval.end,
			},
			value: ids,
		};

		return this._http.post<IUnitRepairChar>(
			this._config.javaApiUrl + '/reliability/timeline/units/',
			payload
		);
	}

	public getFilter(): Observable<IFilter[]> {
		const url = `${this._config.javaApiUrl}/reliability/statistic/getfilter`;

		return this._http.get<IFilter[]>(url);
	}

	public getTotalStatLocation(data: IPostTotalStat): Observable<IStatTotal[]> {
		const url = `${this._config.javaApiUrl}/reliability/statistic/total`;

		return this._http.post<IStatTotal[]>(url, data);
	}

	public recalculateUnitUsing(data): Observable<IStatTotal[]> {
		const url = `${this._config.javaApiUrl}/reliability/statistic/clean/parameter`;

		return this._http.post<IStatTotal[]>(url, data);
	}

	public getDynamicStatUnits(data: IPostDynamicStatUnits): Observable<IGraph[]> {
		const url = `${this._config.javaApiUrl}/reliability/statistic/total/dynamic/unit`;
		return this._http.post<IGraph[]>(url, data);
	}

	public getRepairTasks(data: {
		intervalDate: IPostInterval;
		value: number[];
	}): Observable<IDayAndRollingRepairTask> {
		const url = `${this._config.javaApiUrl}/sdweb/sdrepairtasks`;
		return this._http.post<IDayAndRollingRepairTask>(url, data);
	}

	public getAlarmLevels(): Observable<IAlarmLevel[]> {
		const url = `${this._config.javaApiUrl}/core/alarms/levels`;

		return this._http.get<IAlarmLevel[]>(url).pipe(
			catchError(error => {
				console.log(`get error with getAlarms: ${error}`);
				return of(error);
			})
		);
	}

	public getPermission(): Observable<IPermissions> {
		const url = `${this._config.javaApiUrl}/auth/permissions`;

		return this._http.get<IPermissions>(url).pipe(
			catchError(error => {
				console.log(`get error with getAccessLevels: ${error}`);
				return of(error);
			})
		);
	}

	public getFtpDirectoryList(path): Observable<IFTP[]> {
		const url = `${this._config.javaApiUrl}/ftp/directory/list`;

		return this._http.post<Observable<IFTP[]>>(url, path).pipe(
			catchError(err => {
				const errorText = err.message ? err.message : '';

				this._notifierService.notify(
					'error',
					'Ошибка при получении данных. ' + errorText
				);

				return of(err);
			})
		);
	}

	public getPDF(payload: { fileName: string; path: string }): Observable<BlobPart> {
		const url = `${this._config.javaApiUrl}/ftp/directory/stream`;
		const httpOptions = {
			responseType: 'arraybuffer' as 'json',
		};

		return this._http.post<BlobPart>(url, payload, httpOptions);
	}

	public getTechprocesStatistic(
		requestParams: IPostTechprocess
	): Observable<IStatTechproces> {
		const url = `${this._config.javaApiUrl}/details/techprocess/statistic`;

		return this._http.post<IStatTechproces>(url, requestParams);
	}

	public getUnitsCoefficients(data: IPostCoefficient): Observable<ICoefficient> {
		const url = `${this._config.javaApiUrl}/reliability/coefficient`;

		return this._http.post<ICoefficient>(url, data);
	}

	public getFrontDescription(): Observable<IFrontDescription> {
		const url = `${this._config.javaApiUrl}/core/info/get-front-description`;

		return this._http.get<IFrontDescription>(url);
	}

	public getFormula(): Observable<IFormula[]> {
		const url = `${this._config.javaApiUrl}/core/formula`;

		return this._http.get<IFormula[]>(url);
	}

	public getFormulasForUnitClass(ucId: number): Observable<IFormula[]> {
		const url = `${this._config.javaApiUrl}/core/formula/unit-class/` + ucId;

		return this._http.get<IFormula[]>(url);
	}

	public getFormulasForUnitClassShortName(
		ucShortNames: string[]
	): Observable<IFormula[]> {
		const url =
			`${this._config.javaApiUrl}/core/formula/unit-class/short-name/` +
			ucShortNames;

		return this._http.get<IFormula[]>(url);
	}

	public setFormulasForUnitClass(formulaId, ucId, poolingTime): Observable<null> {
		const url =
			`${this._config.javaApiUrl}/core/formula/` +
			formulaId +
			`/unit-class/` +
			ucId +
			'?pooling=' +
			poolingTime;

		return this._http.post<null>(url, null);
	}

	public deleteFormulasForUnitClass(formulaId, ucId): Observable<null> {
		const url =
			`${this._config.javaApiUrl}/core/formula/` +
			formulaId +
			`/unit-class/` +
			ucId;

		return this._http.delete<null>(url);
	}

	public getUnitsFavorites(): Observable<number[]> {
		const url = `${this._config.javaApiUrl}/core/favorites/`;

		return this._http.get<number[]>(url);
	}

	public getWorkflowStatusTransitions(
		workflowId: number
	): Observable<TaskStatusTransitionDTO[]> {
		return this._sdApiService.get<TaskStatusTransitionDTO[]>(
			`${apiState.SDGateway}/workflow/${workflowId}/statustransition`
		);
	}

	public getStatusesByWorkflow(workflowId: number): Observable<number[]> {
		return this._sdApiService.get<number[]>(
			`${apiState.SDGateway}/workflow/status/${workflowId}`
		);
	}

	public getWorkflowByNomenclature(nomId: number): Observable<IWorkflowData[]> {
		return this._sdApiService.get<IWorkflowData[]>(
			`${apiState.SDGateway}/nomenclature/get-workflow-by-nomenclature/${nomId}`
		);
	}

	public getTaskStatusTransition(
		typeTaskId: number,
		serviceId: number
	): Observable<TaskStatusTransitionDTO[]> {
		return this._http.get<TaskStatusTransitionDTO[]>(
			`${this._config.javaApiUrl}/sdweb/workflow/${serviceId}/${typeTaskId}`
		);
	}

	public createOrUpdate<I extends { id?: number; entity?: { id?: number } }, O>(
		url: string,
		data: I
	): Observable<I> {
		if (
			(data.id && data.id > 0) ||
			(data.entity && data.entity.id && data.entity.id > 0)
		) {
			return this._sdApiService.update<I, O>(url, data);
		} else {
			return this._sdApiService.create<I, O>(url, data);
		}
	}

	public delete<I, O>(url: string, data: I): Observable<O> {
		return this._sdApiService.delete(url, data);
	}

	public getServices(url: string): Observable<SDService[]> {
		return this._sdApiService.get(url);
	}

	public getTaskList(url: string): Observable<ISDTask[]> {
		return this._sdApiService.get(url);
	}

	public getTasksListByFilter(filter: ISDTaskFilter): Observable<ISDTask[]> {
		return this._http.post<ISDTask[]>(
			`${this._config.javaApiUrl}/sdweb/task/filter`,
			filter
		);
	}

	public getTasksListByName(name: string): Observable<ISDTaskIncomplete[]> {
		return this._http.get<ISDTaskIncomplete[]>(
			`${this._config.javaApiUrl}/sdweb/task/find/matching/task/${name}`
		);
	}

	public rebuildJira(filter): Observable<ISDTaskIncomplete[]> {
		return this._http.post<ISDTaskIncomplete[]>(
			`${this._config.javaApiUrl}/sdweb/update/jira-to-sd-update`,
			filter
		);
	}

	public getTasksByIds(ids: number[]): Observable<ISDTask[]> {
		const data = {
			value: ids,
		};

		return this._http.post<ISDTask[]>(
			`${this._config.javaApiUrl}/sdweb/task/find`,
			data
		);
	}

	public getHistoryChangesByTaskId(id: number): Observable<ITaskParameter[]> {
		return this._http.get<ITaskParameter[]>(
			`${this._config.javaApiUrl}/sdweb/task/get-task-history-by-id/${id}`
		);
	}

	public getPersonalNotActiveTasks(interval: IPartialInterval): Observable<{
		executors: ISDTask[];
		observers: ISDTask[];
	}> {
		return this._http.post<{
			executors: ISDTask[];
			observers: ISDTask[];
		}>(`${this._config.javaApiUrl}/sdweb/task/notActive/`, interval);
	}

	public getPersonalActiveTasks(interval: IPartialInterval): Observable<{
		executors: ISDTask[];
		observers: ISDTask[];
	}> {
		return this._http.post<{
			executors: ISDTask[];
			observers: ISDTask[];
		}>(`${this._config.javaApiUrl}/sdweb/task/active/`, interval);
	}

	public getEmployees(url: string): Observable<SDUser[]> {
		return this._sdApiService.get(url);
	}

	public getLastReleaseNotes(): Observable<IReleaseNotes> {
		const url = `${this._config.javaApiUrl}/core/release-notes`;

		// @ts-ignore
		return this._http.get<IReleaseNotes>(url, {responseType: 'text'});
	}

	public getUnitsIdsOverIdleTime(): Observable<number[]> {
		const url = `${this._config.javaApiUrl}/core/unit/over-max-idle-time`;

		return this._http.get<number[]>(url);
	}

	public getPCTerminalByMacAddresses(
		macAddresses: string[]
	): Observable<IPCTerminal[]> {
		const data = {
			filter: macAddresses,
		};

		return this._http.post<IPCTerminal[]>(
			`${this._config.javaApiUrl}/core/pc-terminal/find-by-mac`,
			data
		);
	}

	public getAllLabelsMovement(): Observable<ILabelMovement[]> {
		return this._http.get<ILabelMovement[]>(
			`${this._config.javaApiUrl}/sdweb/label-movement`
		);
	}

	public getHistoryMovementByRFid(rfid: string): Observable<IMovement[]> {
		return this._http.get<IMovement[]>(
			`${this._config.javaApiUrl}/sdweb/movement/get-by-rfid/${rfid}`
		);
	}

	public setMovement(movement: IMovement): Observable<void> {
		return this._http.post<void>(
			`${this._config.javaApiUrl}/sdweb/movement`,
			movement
		);
	}

	public getAvailableLocationsTerminal(
		macAddresses: string[]
	): Observable<number[]> {
		const data = {
			filter: macAddresses,
		};

		return this._http.post<number[]>(
			`${this._config.javaApiUrl}/core/pc-terminal/get-terminal-group-by-mac`,
			data
		);
	}

	public getMesParameterByTaskTypeId(id: number): Observable<IMesParameter[]> {
		return this._http.get<IMesParameter[]>(
			`${this._config.javaApiUrl}/sdweb/plan/mes-parameter-by-task-type/${id}`
		);
	}

	public getDictionaryByShortName(shortName: string): Observable<IDictionary[]> {
		return this._http.get<IDictionary[]>(
			`${this._config.javaApiUrl}/sdweb/getDictionaryBy/${shortName}`
		);
	}

	public setFileSDTask(file: File, taskId: number): Observable<string> {
		const formData: FormData = new FormData();

		formData.append('file', file);
		formData.append('fileName', file.name);

		const url = `${this._config.javaApiUrl}/sdweb/file/${taskId}`;

		// @ts-ignore
		return this._http.post<string>(url, formData, {responseType: 'text'});
	}


	public getDynamicStatLocation(
		data: IPostDynamicStatLocation
	): Observable<IGraph[]> {
		const url = `${this._config.javaApiUrl}/reliability/statistic/total/dynamic`;

		return this._http.post<IGraph[]>(url, data);
	}

	public getTotalStatUnits(data: IPostTotalStatUnits): Observable<IGraph[]> {
		const url = `${this._config.javaApiUrl}/reliability/statistic/total/units`;

		return this._http.post<IGraph[]>(url, data);
	}


	public getSubordinates(id: number): Observable<IUser[]> {
		const url = `${this._config.javaApiUrl}/auth/user/by-leader/${id}`;
		return this._http.get<IUser[]>(url);
	}

	public saveSubordinatesChanges(body): Observable<void> {
		const url = `${this._config.javaApiUrl}/auth/user/update-subordinate-list`;
		return this._http.post<void>(url, body);
	}
}
