import {HttpClient} from '@angular/common/http';
import {ConfigService, IConfig} from '@shared/services/config.service';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class BaseRepository {
	protected _config: Pick<IConfig, 'javaApiUrl' | 'apiUrl' | 'rootLocationId'>;

	constructor(
		protected _http: HttpClient,
		protected _configService: ConfigService,
	) {
		this._config = this._configService.getConfig([
			'apiUrl',
			'javaApiUrl',
			'rootLocationId'
		]);
	}
}
