import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ISDTaskCommentCreate, ISDTaskCommentEdit, ITaskParameter} from '../../modules/sd/models/sd-task.interface';
import {BaseRepository} from './base-repository';

@Injectable({
	providedIn: 'root',
})
export class SdApiRepository extends BaseRepository {
	public get<T>(_url: string): Observable<T> {
		const url = `${this._config.javaApiUrl}/${_url}`;

		return this._http.get<T>(url).pipe(
			catchError((error) => {
				console.log(error);

				return of(error);
			})
		);
	}

	public create<I, O>(_url: string, data: I): Observable<I> {
		const url = `${this._config.javaApiUrl}/${_url}`;

		return this._http.post(url, data).pipe(
			catchError((error) => {
				console.log(error);

				return of(error);
			})
		);
	}

	public update<I, O>(_url: string, data: I): Observable<I> {
		const url = `${this._config.javaApiUrl}/${_url}`;

		return this._http.put(url, data).pipe(
			catchError((error) => {
				console.log(error);

				return of(error);
			})
		);
	}

	public delete<I, O>(_url: string, data: I): Observable<O> {
		const url = `${this._config.javaApiUrl}/${_url}`;

		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: data
		};

		return this._http.delete(url, options).pipe(
			catchError((error) => {
				console.log(error);

				return of(error);
			})
		);
	}

	public createComment(comment: ISDTaskCommentCreate, taskId: number): Observable<ITaskParameter> {
		const url = `${this._config.javaApiUrl}/sdweb/task/save-add/${taskId}/task_comment`;

		return this._http.post<ITaskParameter>(url, comment);
	}

	public editComment(comment: ISDTaskCommentEdit, taskId: number): Observable<ITaskParameter> {
		const url = `${this._config.javaApiUrl}/sdweb/task/save-add/${taskId}/task_comment`;

		return this._http.post<ITaskParameter>(url, comment);
	}
}
